<script setup>
import Button from '@/Components/Button/Button.vue';
import Modal from '@/Components/Popup/Modal.vue';
import { useForm } from '@inertiajs/vue3';
import { inject } from 'vue';
const route = inject('route');
const props = defineProps({
    showing: Boolean,
    application: Object,
});

const emits = defineEmits(['update:showing']);

const closeForm = () => emits('update:showing', false);

const form = useForm({ application_id: '' });

const sendForm = () => {
    form.application_id = props.application.id;
    form.post(route('location.accepted-applications.store'), {
        onSuccess: closeForm,
    });
};
</script>

<template>
    <Modal :open="showing" @closing="closeForm">
        <template #default>
            <p class="mt-3 text-base text-blue">{{ $t('Are you sure you want to accept this fl@xr?') }}</p>
        </template>
        <template #button-bar>
            <Button @click="sendForm" :processing="form.processing" :recentSuccessful="form.recentSuccessful">
                {{ $t('Accept') }}
            </Button>
            <Button @click="closeForm">
                {{ $t('Cancel') }}
            </Button>
        </template>
    </Modal>
</template>
